import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';

import Utils from '../../utils/Utils'
import { Typography } from '@material-ui/core';
import Auth from '../../utils/Auth';

const useStyles = makeStyles(theme => ({
    root: {
        width: '90% !important',
        marginTop: '15px !important',
        maxWidth: 700,

        justifySelf: 'end',

        // display: 'grid', //3
        // gridTemplateColumns: '1fr 1fr', //3

        // width: '90% !important',
        // 'justifySelf': 'end',
        // 'margin': 'inherit',
        'display': 'grid',
        'gridTemplateColumns': '47.5% 47.5%',
        'columnGap': '5%',
        '@media screen and (max-width: 1000px)': {
            margin: '0 auto !important',
        },
    },
    rootSettingsMultipleCatsAndProds: {
        width: '90% !important',
        marginTop: '15px !important',
        maxWidth: 700,

        justifySelf: 'end',

        // display: 'grid', //3
        // gridTemplateColumns: '1fr 1fr', //3

        // width: '90% !important',
        // 'justifySelf': 'end',
        // 'margin': 'inherit',
        'display': 'grid',
        'gridTemplateColumns': '47.5% 47.5%',
        'columnGap': '5%',

        '@media screen and (max-width: 1000px)': {
            margin: '0 auto !important',
        },

        justifySelf: 'center',
        // New Changes ---
        'gridTemplateColumns': '22% 22% 22% 22% 22% 22% 22%',
        'columnGap': '4%',
        margin: '0 auto',
        justifyContent: 'center',
        '@media screen and (max-width: 1440px)': {
            'gridTemplateColumns': '22% 22% 22% 22% 22% 22%',
        },
        '@media screen and (max-width: 1240px)': {
            'gridTemplateColumns': '22% 22% 22% 22% 22%',
        },
        '@media screen and (max-width: 900px)': {
            'gridTemplateColumns': '22% 22% 22% 22%',
        },
        '@media screen and (max-width: 750px)': {
            'gridTemplateColumns': '30% 30% 30%',
        },
        '@media screen and (max-width: 550px)': {
            'gridTemplateColumns': '42% 42%',
        },
        '@media screen and (max-width: 350px)': {
            'gridTemplateColumns': '50% 50%',
        },
    },
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridColumnGap: 10,
    itemTypography: {
        display: 'contents',
    },
    subcatRow: {
        // '&:first-child': {
        //     marginTop: 15,
        // },
        '&:last-child': { // temp unable to hive navbar fix
            marginBottom: 100,
        },
        background: 'rgba(255, 255, 255, 0.65)',
        width: '95%',
        margin: '0 auto',
        marginBottom: 10,
        borderRadius: 7,
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.71)',
        },
        padding: 8,
    },
    avatar: {
        width: 55,
        height: 55,
        marginRight: 15,
        border: '1px solid #ccc',
    },

    columnsButtonHierarchy: {
        cursor: 'pointer',
        width: '95%',
        margin: '0 auto',
        background: 'rgba(255, 255, 255, 0.65)',
        borderRadius: 7,
        marginBottom: 20,
        minHeight: 200,
        overflow: 'hidden',
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.71)',
        },
    },

    imageContainer: {
        borderRadius: 7,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: 150,
        transition: 'all .2s',
        transform: 'scale(1)',
        '&:hover': {
            transform: 'scale(1.12)',
        },
    }

}));

export default function ColumnButton(props) {
    const classes = useStyles();

    const { registeredUserDiscount } = props;
    const token_not_spot = Auth.getTokenOrderType() != 1;
    const token_spot = Auth.getTokenOrderType() == 1;
    const token_not_spot_browse = Auth.getTokenOrderType() != 4;
    const token_spot_browse = Auth.getTokenOrderType() == 4;
    const token_not_delivery = Auth.getTokenOrderType() != 2;
    const token_delivery = Auth.getTokenOrderType() == 2;

    return (
        <div className={props.settings.multiple_columns_cats_and_prods_design && props.settings.multiple_columns_cats_and_prods_design.value == 1 ? classes.rootSettingsMultipleCatsAndProds : classes.root} data-columnbutton={1} style={{
            // width: '100%',
            margin:
                ((props.settings.promotions_html && props.settings.promotions_html.value)
                    || (props.settings.promotions_text && props.settings.promotions_text.value)
                    || (registeredUserDiscount && registeredUserDiscount.discount && Object.values(registeredUserDiscount.discount).length > 0)
                )
                    ? 'inherit' : '0 auto',
        }}>
            {Object.values(props.items).sort(Utils.sortPriority()).map((item, k) => {
                if (token_not_spot && item.show_cat_only_for_spot_orders == 1) { return null }
                if (token_spot && item.hide_cat_only_for_spot_orders == 1) { return null }
                if (token_not_spot_browse && item.show_only_for_spot_browse == 1) { return null }
                if (token_spot_browse && item.hide_only_for_spot_browse == 1) { return null }
                if (token_delivery && item.hide_cat_only_for_delivery_orders == 1) { return null }
                if (token_not_delivery && item.show_cat_only_for_delivery_orders == 1) { return null }

                return (
                    <React.Fragment>

                        <Grow in={true} timeout={{ enter: Utils.calculateAnimationTimeout(k), exit: Utils.calculateAnimationTimeout(k) }} key={k}>
                            <div onClick={() => props.categoryClickHandler(item.id)}
                                className={classes.columnsButtonHierarchy}
                                data-class="columnsButtonHierarchy"
                            >

                                <div className={classes.imageContainer}
                                    style={{
                                        backgroundImage: 'url(' + Utils.getSubcategoryImagePath(item.icon, 1) + ')',
                                    }}>
                                </div>

                                <div
                                    style={{
                                        padding: 8,
                                        padding: 10,
                                        textAlign: 'center',

                                        minHeight: 50,
                                        /* vertical-align: middle; */
                                        display: 'grid',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {/* <Avatar className={classes.avatar} src={Utils.getSubcategoryImagePath(item.icon, 1)} 
                                alt={item && item.translations && props.currLang && item.translations[props.currLang] && item.translations[props.currLang].name || item.name} /> */}

                                    <Typography component="h3" variant="span" className={classes.itemTypography}>
                                        <span style={{ fontSize: '1.1rem' }}>
                                            {item && item.translations && props.currLang && item.translations[props.currLang] && (Utils.getSettingsValue(props.settings, 'replace_slash_with_new_line_category_list') == 1 ? Utils.replaceString(item.translations[props.currLang].name, '/', "<br/>", 1) : item.translations[props.currLang].name) || (Utils.getSettingsValue(props.settings, 'replace_slash_with_new_line_category_list') == 1 ? Utils.replaceString(item.name, '/', "<br/>", 1) : item.name)}
                                        </span>
                                    </Typography>


                                    <div dangerouslySetInnerHTML={{
                                        __html: item.description ? item.description : ''
                                    }}></div>

                                </div>
                            </div>
                        </Grow>

                        {k == 2 && props.settings && props.settings.google_adsense_ads_categories && props.settings.google_adsense_ads_categories.value && props.settings.google_adsense_ads_categories.value != "{}" ?
                            <div>
                                <div dangerouslySetInnerHTML={{
                                    __html: props.settings.google_adsense_ads_categories.value
                                }}>
                                </div>
                            </div>
                            :
                            null}
                    </React.Fragment>

                )
            })}
        </div>
    );
}
